(function() {
  const myQuestions2 = [
    {
      question: "Select the correct corresponding completion to the following sentence:<em><br> By the time Alex reads the book, he ________ the 11th grade.</em>",
      answers: {
        a: "will be finish",
        b: "will finish",
        c: "will have finished",
        d: "have finished"
      },
      correctAnswer: "c"
    },
    {
      question: "Many people believe___ the idea that hard work pays.",
      answers: {
        a: "on",
        b: "in",
        c: "of",
        d: "off",
        e: "to"
      },
      correctAnswer: "b"
    },
    {
      question: "Before Tamais bought the ticket, Sarah _______ the last available front row seat.",
      answers: {
        a: "purchase",
        b: "will purchase",
        c: "purchased",
        d: "had purchased"
      },
      correctAnswer: "d"
    }
  ];

  function buildQuiz2() {
    // we'll need a place to store the HTML output
    const output = [];

    // for each question...
    myQuestions2.forEach((currentQuestion2, questionNumber2) => {
      // we'll want to store the list of answer choices
      const answers = [];

      // and for each available answer...
      for (letter in currentQuestion2.answers) {
        // ...add an HTML radio button
        answers.push(
          `<label>
             <input type="radio" name="question${questionNumber2}" value="${letter}">
              ${letter} :
              ${currentQuestion2.answers[letter]}
           </label>`
        );
      }

      // add this question and its answers to the output
      output.push(
        `<div class="slide2">
           <div class="question"> ${currentQuestion2.question} </div>
           <div class="answers"> ${answers.join("")} </div>
         </div>`
      );
    });

    // finally combine our output list into one string of HTML and put it on the page
    quizContainer2.innerHTML = output.join("");
  }

  function showResults2() {
    // gather answer containers from our quiz
    const answerContainers = quizContainer2.querySelectorAll(".answers");

    // keep track of user's answers
    let numCorrect = 0;

    // for each question...
    myQuestions2.forEach((currentQuestion, questionNumber) => {
      // find selected answer
      const answerContainer = answerContainers[questionNumber];
      const selector = `input[name=question${questionNumber}]:checked`;
      const userAnswer = (answerContainer.querySelector(selector) || {}).value;

      // if answer is correct
      if (userAnswer === currentQuestion.correctAnswer) {
        // add to the number of correct answers
        numCorrect++;

        // color the answers green
        answerContainers[questionNumber].style.color = "lightgreen";
      } else {
        // if answer is wrong or blank
        // color the answers red
        answerContainers[questionNumber].style.color = "red";
      }
    });

    // show number of correct answers out of total
    resultsContainer2.innerHTML = `${numCorrect} out of ${myQuestions2.length}`;
  }

  function showSlide2(n) {
    slides2[currentSlide2].classList.remove("active-slide2");
    slides2[n].classList.add("active-slide2");
    currentSlide2 = n;

    if (currentSlide2 === 0) {
      previousButton2.style.display = "none";
    } else {
      previousButton2.style.display = "inline-block";
    }

    if (currentSlide2 === slides2.length - 1) {
      nextButton2.style.display = "none";
      submitButton2.style.display = "inline-block";
    } else {
      nextButton2.style.display = "inline-block";
      submitButton2.style.display = "none";
    }
  }

  function showNextSlide2() {
    showSlide2(currentSlide2 + 1);
  }

  function showPreviousSlide2() {
    showSlide2(currentSlide2 - 1);
  }

  const quizContainer2 = document.getElementById("quiz2");
  const resultsContainer2 = document.getElementById("results2");
  const submitButton2 = document.getElementById("submit2");

  // display quiz right away
  buildQuiz2();

  const previousButton2 = document.getElementById("previous2");
  const nextButton2 = document.getElementById("next2");
  const slides2 = document.querySelectorAll(".slide2");
  let currentSlide2 = 0;

  showSlide2(0);

  // on submit, show results
  submitButton2.addEventListener("click", showResults2);
  previousButton2.addEventListener("click", showPreviousSlide2);
  nextButton2.addEventListener("click", showNextSlide2);
})();
