(function() {
  const myQuestions3 = [
    {
      question: "<span style=\"font-size: 16px;\">What is the closest description of the author's tone in the following passage?<br></span><br>The Hobbit and the Lord of the Rings series are grouped among the most popular books in the world, having sold tens of millions of copies. The Rings trilogy was also adapted by director Peter Jackson into a highly popular, award-winning trio of films starring Ian McKellen, Elijah Wood, Cate Blanchett and Viggo Mortensen, among others. Jackson also directed a three-part Hobbit movie adaptation starring Martin Freeman, which was released from 2012 to 2014.<br>Tolkien's son Christopher has edited several works that weren't completed at the time of his father's death, including The Silmarillion and The Children of Húrin, which were published posthumously. The Art of the Hobbit was published in 2012, celebrating the novel's 75th anniversary by presenting Tolkien's original illustrations.",
      answers: {
        a: "Amused",
        b: "Anxious",
        c: "Cynical",
        d: "Indifferent"
      },
      correctAnswer: "d"
    },
    {
      question: "<span style=\"font-size: 16px;\">What is the closest meaning to <em>posthumously</em> in the passage?</span><br><br>The Hobbit and the Lord of the Rings series are grouped among the most popular books in the world, having sold tens of millions of copies. The Rings trilogy was also adapted by director Peter Jackson into a highly popular, award-winning trio of films starring Ian McKellen, Elijah Wood, Cate Blanchett and Viggo Mortensen, among others. Jackson also directed a three-part Hobbit movie adaptation starring Martin Freeman, which was released from 2012 to 2014.<br>Tolkien's son Christopher has edited several works that weren't completed at the time of his father's death, including The Silmarillion and The Children of Húrin, which were published posthumously. The Art of the Hobbit was published in 2012, celebrating the novel's 75th anniversary by presenting Tolkien's original illustrations.",
      answers: {
        a: "early",
        b: "before the time",
        c: "after death",
        d: "secondary"
      },
      correctAnswer: "c"
    },
    {
      question: "<span style=\"font-size: 16px;\">From the passage below one could logically infer...</span><br>The Hobbit and the Lord of the Rings series are grouped among the most popular books in the world, having sold tens of millions of copies. The Rings trilogy was also adapted by director Peter Jackson into a highly popular, award-winning trio of films starring Ian McKellen, Elijah Wood, Cate Blanchett and Viggo Mortensen, among others. Jackson also directed a three-part Hobbit movie adaptation starring Martin Freeman, which was released from 2012 to 2014.<br>Tolkien's son Christopher has edited several works that weren't completed at the time of his father's death, including The Silmarillion and The Children of Húrin, which were published posthumously. The Art of the Hobbit was published in 2012, celebrating the novel's 75th anniversary by presenting Tolkien's original illustrations.",
      answers: {
        a: "Lord of the Rings characters lack character development",
        b: "It is difficult for Peter Jackson to make more Lord of the Rings films",
        c: "Tolkien's son is a writer",
        d: "The Hobbit had more sales than the Lord of the Rings"
      },
      correctAnswer: "c"
    }
  ];

  function buildQuiz3() {
    // we'll need a place to store the HTML output
    const output = [];

    // for each question...
    myQuestions3.forEach((currentQuestion3, questionNumber3) => {
      // we'll want to store the list of answer choices
      const answers = [];

      // and for each available answer...
      for (letter in currentQuestion3.answers) {
        // ...add an HTML radio button
        answers.push(
          `<label>
             <input type="radio" name="question${questionNumber3}" value="${letter}">
              ${letter} :
              ${currentQuestion3.answers[letter]}
           </label>`
        );
      }

      // add this question and its answers to the output
      output.push(
        `<div class="slide3">
           <div class="question3"> ${currentQuestion3.question} </div>
           <div class="answers"> ${answers.join("")} </div>
         </div>`
      );
    });

    // finally combine our output list into one string of HTML and put it on the page
    quizContainer3.innerHTML = output.join("");
  }

  function showResults3() {
    // gather answer containers from our quiz
    const answerContainers = quizContainer3.querySelectorAll(".answers");

    // keep track of user's answers
    let numCorrect = 0;

    // for each question...
    myQuestions3.forEach((currentQuestion, questionNumber) => {
      // find selected answer
      const answerContainer = answerContainers[questionNumber];
      const selector = `input[name=question${questionNumber}]:checked`;
      const userAnswer = (answerContainer.querySelector(selector) || {}).value;

      // if answer is correct
      if (userAnswer === currentQuestion.correctAnswer) {
        // add to the number of correct answers
        numCorrect++;

        // color the answers green
        answerContainers[questionNumber].style.color = "lightgreen";
      } else {
        // if answer is wrong or blank
        // color the answers red
        answerContainers[questionNumber].style.color = "red";
      }
    });

    // show number of correct answers out of total
    resultsContainer3.innerHTML = `${numCorrect} out of ${myQuestions3.length}`;
  }

  function showSlide3(n) {
    slides3[currentSlide3].classList.remove("active-slide3");
    slides3[n].classList.add("active-slide3");
    currentSlide3 = n;

    if (currentSlide3 === 0) {
      previousButton3.style.display = "none";
    } else {
      previousButton3.style.display = "inline-block";
    }

    if (currentSlide3 === slides3.length - 1) {
      nextButton3.style.display = "none";
      submitButton3.style.display = "inline-block";
    } else {
      nextButton3.style.display = "inline-block";
      submitButton3.style.display = "none";
    }
  }

  function showNextSlide3() {
    showSlide3(currentSlide3 + 1);
  }

  function showPreviousSlide3() {
    showSlide3(currentSlide3 - 1);
  }

  const quizContainer3 = document.getElementById("quiz3");
  const resultsContainer3 = document.getElementById("results3");
  const submitButton3 = document.getElementById("submit3");

  // display quiz right away
  buildQuiz3();

  const previousButton3 = document.getElementById("previous3");
  const nextButton3 = document.getElementById("next3");
  const slides3 = document.querySelectorAll(".slide3");
  let currentSlide3 = 0;

  showSlide3(0);

  // on submit, show results
  submitButton3.addEventListener("click", showResults3);
  previousButton3.addEventListener("click", showPreviousSlide3);
  nextButton3.addEventListener("click", showNextSlide3);
})();
